




import Component from "vue-class-component";
import Vue from "vue";
import BoardsTable from "@/components/pb/BoardsTable.vue";

@Component({
    components: {BoardsTable}
})
export default class TemplatesManager extends Vue {

}
